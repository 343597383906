export default {
    isEmptyObject(obj) {
        var name;
        for ( name in obj ) {
            return false;
        }
        return true;
    },
    isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]'
    }
}
